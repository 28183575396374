:root {
  --dark0_hard: #1d2021;
  --dark0: #282828;
  --dark0_soft: #32302f;
  --dark1: #3c3836;
  --dark2: #504945;
  --dark3: #665c54;
  --dark4: #7c6f64;
  --dark4_256: #7c6f64;
  --gray: #928374;
  --gray2: #928374;
  --light0_hard: #f9f5d7;
  --light0: #fbf1c7;
  --light0_soft: #f2e5bc;
  --light1: #ebdbb2;
  --light2: #d5c4a1;
  --light3: #bdae93;
  --light4: #a89984;
  --light4_256: #a89984;
  --bright_red: #fb4934;
  --neutral_red: #cc241d;
  --faded_red: #9d0006;
  --bright_yellow: #fabd2f;
  --neutral_yellow: #d79921;
  --faded_yellow: #b57614;
  --bright_aqua: #8ec07c;
  --neutral_aqua: #689d6a;
  --faded_aqua: #427b58;
  --bright_green: #b8bb26;
  --faded_green: #79740e;
  --neutral_green: #98971a;
  --bright_blue: #83a598;
  --neutral_blue: #458588;
  --faded_blue: #076678;
  --bright_purple: #d3869b;
  --neutral_purple: #b16286;
  --faded_purple: #8f3f71;
  --bright_orange: #fe8019;
  --neutral_orange: #d65d0e;
  --faded_orange: #af3a03;
}
* {
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: var(--dark0);
}
.App {
  justify-content: center;
  color: var(--light4_256);
  display: grid;
  grid-template-columns: 20vw 20vw 40vw 20vw;
  grid-template-rows: 20vh 60vh 20vh;
}
.content {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 3;
  margin-left: 20px;
  position: relative;
  height: min(20vw, 60vh);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.ltext {
  font-size: calc(min(20vw, 60vh) / 15);
  padding-top: 3px;
}
.footer {
  left: 0;
}
ion-icon {
  color: var(--faded_blue);
  font-size: calc(min(20vw, 60vh) / 12);
  margin-right: 10px;
  bottom: 0%;
}
ion-icon:hover {
  color: var(--faded_green);
}

.profilepic {
  width: min(20vw, 60vh);
  height: min(20vw, 60vh);
  border-radius: calc(min(20vw, 60vh) / 2);
  background-image: url("../public/profilepic.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 2;
  border: 3px solid var(--bright_aqua);
}
.maindisc {
  display: flex;
}
.name {
  font-size: calc(min(20vw, 60vh) / 6);
  font-weight: bold;
  color: var(--faded_orange);
}
@media only screen and (max-width: 600px) {
  .App {
    grid-template-rows: 20vh 20vh 40vh 20vh;
    grid-template-columns: 20vw 60vw 20vw;
  }
  .content {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 2;
    margin-left: 0px;
    margin-top: 5px;
    height: min(20vh, 60vw);
    align-items: center;
    padding: 5px;
  }
  .profilepic {
    width: min(20vh, 60vw);
    height: min(20vh, 60vw);
    border-radius: calc(min(20vh, 60vw) / 2);
    align-self: center;
    justify-self: center;
  }
  .name {
    font-size: calc(min(20vw, 60vh) / 4);
    overflow: hidden;
  }
  ion-icon {
    font-size: calc(min(20vw, 60vh) / 6);
  }
  .ltext {
    font-size: calc(min(20vw, 60vh) / 9);
  }
}
